@font-face {
    font-family: 'Abel';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Abel Regular'), local('Abel-Regular'), url(abel.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
  
$name-color: rgb(73, 141, 250);
$main-background: rgb(23, 23, 23);

:root {
    height: 100%;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background: $main-background;
    font-family: 'Abel';
    color: white;
    font-size: 15pt;
}


.name, .name a {
    color: $name-color;
    text-decoration: none;
}


.article {
    width: 50%;
    margin: 0 auto;
    margin-top: 60px;

    .blog-disclaimer {
        font-size: 15px;
        padding-top: 10px;
        display: block;
    }

    h1 {
        padding-top: 6px;
        line-height: 50px;
        font-size: 30px;
    }

    h2 {
        padding-top: 11px;
        font-size: 22px;
    }

    .post-list {
        padding: 0;
        margin-top: 20px;
        font-size: 20px;
    }

    .post-links {
        margin: 20px 0 0 0;
        li {
            margin-bottom: 5px;
        }
        a {
            color: white;
        }
    }

    p, li {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        font-family: Helvetica, Arial, sans-serif;
        font-weight: 300;
        line-height: 25px;
    }

    div.footnotes {
        padding-top: 10px;
        font-style: italic;
        li, p {
            font-size: 13px;
        }
    }

    .header-img {
        width: 100%;
        margin: 30px 0 30px 0;
    }

    .article-img {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        figcaption {
            width: 80%;
            font-size: 17px;
            font-style: italic;
        }
    }

    .img-50 {
        width: 50%;
    }

    ul {
        margin-bottom: 15px;
    }

    li {
        padding: 0;
        margin: 0;
        margin-left: 25px;
        margin-bottom: 5px;
    }

    .article-meta {
        z-index: 10;
        font-size: 17px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgb(51, 51, 51);
        position: sticky;
        top: 0;
        background-color: $main-background;
        display: flex;
        justify-content: space-between;

        .heading-links {
            a {
                color: white;
                text-decoration: none;
            }
        }


        .author a {
            color: $name-color;
            text-decoration: none;
        }

        .date {
            color: rgba(255, 240, 240, 0.728);
        }
    }

    .article-content {
        a {
            color: $name-color;
            font-weight: bold;
        }

        blockquote {
            border-left: 3px solid #646464;
            padding-left: 10px;
            margin-left: 10px;
            font-style: italic;
        }

        strong {
            font-weight: bold;
        }

        .code {
            font-size: 15px;
            padding: 10px;
            background-color: black;

            .line-numbers {
                display: none;
            }
        }

        mjx-container {
            display: inline-grid;
            overflow-x: auto;
            overflow-y: hidden;
            max-width: 100%;
        }
    }


}

@media (max-width: 1200px) {
    .article {
        width: 90%;
    }
}

@media (min-width: 1600px) {
    .article {
        width: 800px;
    }
}

footer {
    color: rgba(255, 240, 240, 0.728);
    font-size: 15px;
    margin-left: -3px;
    padding-bottom: 30px;
}